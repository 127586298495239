
import { PropType, defineComponent, watch } from "vue";
import { Modal } from "bootstrap";
export default defineComponent({
  name: "ModalDelRegister",
  props: {
    header: {
      type: String,
      default: "Excluir registro!"
    },
    name: {
      type: String,
      default: "Registro"
    },
    toggle: {
      type: Boolean,
      default: false
    },
    handleButtonClick: {
      type: Function as PropType<() => void>,
      required: true,
    },
    storeListBySku: {
      type: Array as PropType<{ fantasy_name: string; cnpj: string }[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    // Variables
    let auxModal

    // Functions
    const openModal = (id: string) => {
      const auxElement = document.querySelector(`#${id}`);
      auxModal = new Modal(auxElement);
      auxModal.show();
    }

    // Life Cycles
    watch(() => props.toggle, () => openModal("ModalDelRegister"))

    return { }
  }
})
