
import axios, { AxiosError } from 'axios';
import { defineComponent, ref, onMounted, watch } from 'vue';
import { useRouter } from "vue-router";
import useAlert from "@/composables/Alert";
import {
  CampHeader,
  CampEmptyListFeedbackV2,
  CampTable,
  CampTableTd,
  CampTableTh,
  CampBtnGenerateExcel
} from '@/components';
import { useLoaderStore } from "@/store/LoaderStore";
import { CampConstrainProportionDiv } from "@/components";
import { CampUndefinedImgURL } from '@/components/CampModalCropImage'
import ModalDelRegister from './Components/ModalDelRegister.vue';
import ModalCopyByStore from "./Components/ModalCopyByStore.vue"
// import { useAuthStore } from "@/store/AuthStore";
import { generateExcel } from "@/services/GenerateExcelService"
import ModalUploadSpreadsheets from "./Components/ModalUploadSpreadsheets.vue";
import { useFilterBaseV2Store } from '@/store/FilterBaseV2Store';

export interface IProductData {
  category: string,
  name: string,
  id?: number,
  points_default: number | string,
  sku: string,
  url_media: string,
  createdFraction?: boolean,
  description: string
}

interface ICompanyResponse {
  data: {
    error: boolean,
    message: string,
    data: IProductData[]
    errorCode: string
  }
}

export const COLS_TABLE = {
  name: "Produtos",
  category: "Categorias",
  points_default: "Pontos",
  sku: "Códigos de barras",
  url_media: "Links das Imagens",
  description: "Descrição",
}

export default defineComponent({
  name: "ProductList",
  components: {
    CampHeader,
    CampEmptyListFeedbackV2,
    CampTable,
    CampTableTd,
    CampTableTh,
    CampConstrainProportionDiv,
    ModalCopyByStore,
    CampBtnGenerateExcel,
    ModalDelRegister,
    ModalUploadSpreadsheets,
  },
  setup() {
    const loaderStore = useLoaderStore();
    const router = useRouter();
    
    const queryObject = ref<{
      noError: boolean,
      data:  null | IProductData[]
    }>({
      noError: false,
      data: null
    })
    const printProducts = ref<null | IProductData[]>(null)
    const keywordSearch = ref("")
    const isLoading = ref(false)
    const dialogVisible = ref({
      xlms: false,
      copy: false
    })
    // const roleLevel = ref();
    // const authStore = useAuthStore();
    const filterBaseV2Store = useFilterBaseV2Store()
    const toggleModalUploadSpreadsheets = ref(false)
    const refreshModalUploadSpreadsheets = ref(false)
    const dataTable = ref<IProductData[]>([])
    const pontueiProduct = ref<boolean>(true)
    const enviroment = ref<string | undefined>(process.env.NODE_ENV)

    async function handleGetProducts() {
      isLoading.value = true
      try {
        let result: ICompanyResponse
        if(pontueiProduct.value) {
          result = await axios.get("/api/getProductList")
        }
        else {
          result = await axios.get("/api/getProductAtriaList")
        }
        const { data } = result
        queryObject.value.data = data.data
        printProducts.value = data.data
      } catch (error) {
        if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        loaderStore.close()
        isLoading.value = false
      }
    }

    function handleFilterObjectsByKeyword(): boolean {
      if(!queryObject.value.data)
        return true
      printProducts.value = queryObject.value.data.filter((item) => {
        for (const key in item) {
          if (key !== 'checked') {
            const propValue = item[key];
            if (typeof propValue === 'string' && propValue.toLowerCase().includes(keywordSearch.value.toLowerCase())) {
              return true;
            }
            if (typeof propValue === 'number' && keywordSearch!.toString() === propValue.toString()) {
              return true;
            }
          }
        }
        return false;
      });
      return false
    }

    function openDragUploadCopy() {
      dialogVisible.value.copy = !dialogVisible.value.copy
    }

    watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())

    // onMounted(async () => {
    //   roleLevel.value = authStore.user.user.role.level
    //   handleGetProducts()
    // })

    const { showTimeAlert } = useAlert()
    const itemDel = ref<IProductData | null>(null)
    const toggleModalDelRegister = ref(false)
    const storeListBySku = ref<{ fantasy_name: string, cnpj: string }[]>([])
    async function handleModalDelRegister(item: IProductData) {
      try {
        storeListBySku.value = new Array()
        loaderStore.open()
        const result = await axios.get(`/api/checkProductInUse/${item.sku}`)
        const stores = result.data.data

        if(!Array.isArray(stores))
          return showTimeAlert("Erro ao consultar produto", "error")

        if(stores.length > 0)
          storeListBySku.value = stores

        toggleModalDelRegister.value = !toggleModalDelRegister.value
        itemDel.value = item
      } catch (error) {
        if(error instanceof AxiosError) {
          showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
      } finally {
        loaderStore.close()
      }
    }
    async function handleConfirmItemDel() {
      if(itemDel.value) {
        try {
          loaderStore.open()
          const result = await axios.delete(`/api/deleteProduct/${itemDel.value.id}`)
          showTimeAlert(`O registro ${itemDel.value.name} foi excluído com sucesso!`)
          handleGetProducts()
          itemDel.value = null
        } catch (error) {
          if(error instanceof AxiosError) {
            showTimeAlert(error.response?.data.message, "error")
        } else {
          showTimeAlert("Algo deu errado!", "error")
        }
        } finally {
          loaderStore.close()
        }
      }
    }

    function handleModalCopyByStoreFromSpreadsheetEvent(data: Record<string, any>[]) {
      try {
        if(!data)
          throw new Error("Planilha incompatível")
        if(Array.isArray(data) && data.length < 1)
          throw new Error("Planilha sem conteúdo")
        const colsUser = Object.keys(data[0])
        const colsStd = Object.values(COLS_TABLE)
        colsUser.forEach(el1 => {
          const notAvailable = !(colsStd.some(el2 => el1 === el2))
          if(notAvailable)
            throw new Error("Planilha incompatível")
        })
        dataTable.value = new Array()
        dataTable.value = data.map(el => ({
          category: el[COLS_TABLE.category],
          name: el[COLS_TABLE.name],
          points_default: el[COLS_TABLE.points_default],
          sku: el[COLS_TABLE.sku],
          url_media: el[COLS_TABLE.url_media],
          description: el[COLS_TABLE.description],
        }))
      } catch (error: any) {
        if(error?.message)
          showTimeAlert(error.message, "error")
        else
          showTimeAlert("Algo deu errado", "error")
        console.error(error)
      }
    }

    function handleRefresh() {
      refreshModalUploadSpreadsheets.value = !refreshModalUploadSpreadsheets.value
      handleGetProducts()
    }

    onMounted(async() => handleGetProducts())

    watch(() => filterBaseV2Store.checkForUpdates(), () => filterBaseV2Store.checkForUpdates() && handleGetProducts())

    watch(() => keywordSearch.value, () => handleFilterObjectsByKeyword())

    watch(() => pontueiProduct.value, () => handleGetProducts(), {deep: true})

    return {
      router,
      queryObject,
      isLoading,
      keywordSearch,
      printProducts,
      handleModalDelRegister,
      toggleModalDelRegister,
      itemDel,
      handleConfirmItemDel,
      CampUndefinedImgURL,
      dialogVisible,
      openDragUploadCopy,
      // roleLevel,
      handleGetProducts,
      generateExcel,
      storeListBySku,
      toggleModalUploadSpreadsheets,
      handleModalCopyByStoreFromSpreadsheetEvent,
      dataTable,
      handleRefresh,
      refreshModalUploadSpreadsheets,
      pontueiProduct,
      enviroment
    }
  }
})
