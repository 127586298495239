
import { PropType, defineComponent, ref, watch } from "vue";
import { Modal } from "bootstrap";
import { CampBtnGenerateExcel } from "@/components";
import { IProductData } from "../ListPage.vue";
import { parseExcel } from "@/services/GenerateExcelService";
import { COLS_TABLE } from "../ListPage.vue";

const INIT_PRINT_PRODUCTS:IProductData[] = [
  {
    category: "",
    name: "",
    id: -1,
    points_default: "",
    sku: "",
    url_media: "",
    description: ""
  }
]

export default defineComponent({
  name: "ModalUploadSpreadsheets",
  components: {
    CampBtnGenerateExcel
  },
  props: {
    header: {
      type: String,
      default: "Importar/Exportar Excel"
    },
    toggle: {
      type: Boolean,
      default: false
    },
    printProducts: {
      type: Array as PropType<IProductData[]>,
      required: true,
    },
    refresh: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    // Variables
    let auxModal
    const file = ref<File | null>(null)
    const inputFileRef = ref<HTMLInputElement | null>(null)
    const dataTable = ref<Record<string, any>[]>([])

    // Functions
    const openModal = (id: string) => {
      const auxElement = document.querySelector(`#${id}`)
      auxModal = new Modal(auxElement)
      auxModal.show()
    }

    function openInput() {
      inputFileRef.value!.click()
    }

    function dropHandler(e) {
      stopDefault(e)

      if (e.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        [...e.dataTransfer.items].forEach((item, i) => {
          // If dropped items aren't files, reject them
          if (item.kind === "file") {
            file.value = item.getAsFile()
          }
        })
      } else {
        // Use DataTransfer interface to access the file(s)
        [...e.dataTransfer.files].forEach((fileParam, i) => {
          file.value = fileParam
        })
      }
    }

    function stopDefault(e) {
      // Prevent default behavior (Prevent file from being opened)
      e.stopPropagation()
      e.preventDefault()
    }

    async function uploadFile(event: any) {
      file.value = event.target.files[0]
    };

    function clearFile() {
      file.value = null
      dataTable.value = []
    }

    async function getDataTable() {
      if(!file.value)
        return
      dataTable.value = await parseExcel(file.value)
    }
    
    function handleButtonClick() {
      emit("event:getDataExcell", [ ...dataTable.value.map(el => ({ ...el })).filter(el => Object.keys(el).length > 0) ])
    }

    // Life Cycles
    watch(() => props.toggle, () => openModal("ModalUploadSpreadsheets"))

    watch(() => file.value, async () => getDataTable())

    watch(() => props.refresh, async () => clearFile())

    return {
      file,
      inputFileRef,
      openInput,
      dropHandler,
      stopDefault,
      uploadFile,
      clearFile,
      handleButtonClick,
      dataTable,
      COLS_TABLE,
      INIT_PRINT_PRODUCTS,
    }
  }
})
